@use "./config" as *;

.waf-detail {
  padding: 0;
  margin: 0;
  padding: calc(var(--full-space) * 2) 0;
  :is(.title, .sub-title) {
    // font-family: $font-bold;
  }
  // .title {
  //     color: var(--kabaddi-secondary-color);
  //     font-size: 1.8rem;
  // }
  .article {
    &-body {
      a {
        color: rgba(var(--light-red-color-rgb), 0.8);
        text-decoration: underline;
      }
      .img-box {
        @include thumb-clip(92%, 95%);
      }
    }
    &-description {
      // margin: 2rem 0;
    }
    &-featured-media {
      margin: 2rem 0;
      + div {
        [data-component="SI-image"] {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
  .meta {
    font: 1.2rem/1.5 $font-regular;
    color: rgba(var(--kabaddi-secondary-color-rgb), 0.8);
    margin-bottom: 1rem;
    display: block;
    &-category {
      display: none;
    }
  }
  .head-wrap {
    margin: .5rem 0;
    @include flex-config(flex, null, space-between, center);
  }
  ol {
    list-style: none;
    counter-reset: list-count;
    li {
      counter-increment: list-count;
      &::before {
        content: counter(list-count) ". ";
        color: var(--blue-color);
        font: 1.4rem/1.4 $font-bold;
      }
    }
  }
  .video-wrap {
    width: 100%;
    video {
      width: 100%;
      height: auto;
    }
  }
  .tagline {
    margin-bottom: var(--half-space);
  }
  .img-box {
    border-radius: 0 0 var(--full-radius) var(--full-radius);
  }
}

@media screen and (min-width: $tablet-min-width) {
  .waf-detail {
    .article {
      &-featured-media {
        margin: 1rem 0;
      }
      &-description {
        margin: 1rem 0;
      }
    }
    .head-wrap {
      height: 4rem;
    }
  }
}
