@use "./config" as *;
.static-content {
  padding: 0 var(--full-space) 2rem;
  margin: 0 var(--half-space-negative);
  .layout-wrapper {
    max-width: var(--container-max-width);
    margin: 0 auto;
    // @include flex-config(flex, null, null, center);
  }
  .title {
    font: 2rem/1.2 $font-bold;
    margin: 0;
  }
  .static-wrapper {
    max-width: var(--container-max-width);
    margin: 0 auto;
    width: 100%;
    padding-right: var(--half-space);
    padding-left: var(--half-space);
    .static-body {
      margin-top: var(--full-space);
    }
  }
  p {
    font: 1.4rem/1.8 $font-regular;
    color: var(--kabaddi-secondary-color);
    margin: var(--full-space) 0;
  }
  .sub-title,
  .highlight {
    font: 1.6rem/1.4 $font-bold;
    color: var(--kabaddi-secondary-color);
    margin-bottom: var(--full-space);
  }
  ul,
  ol {
    padding-left: var(--full-space);
    list-style-type: auto;
    li {
      font-size: 1.4rem;
      color: var(--kabaddi-secondary-color);
      margin: 2rem 0;
      &::marker {
        font-size: 1.6rem;
        font-family: $font-bold;
      }
      p {
        margin: 0 var(--half-space) var(--full-space) var(--half-space);
      }
    }
  }
  .lower-roman {
    list-style-type: lower-roman;
  }
  .upper-roman {
    list-style-type: upper-roman;
  }
  .disc-list {
    list-style-type: disc;
  }
  .circle-list {
    list-style-type: circle;
  }
  .lower-alpha {
    list-style-type: lower-alpha;
  }
  .upper-alpha {
    list-style-type: upper-alpha;
  }
  .counter-list {
    counter-reset: item;
    li {
      &:before {
        content: counters(item, ".") " ";
        counter-increment: item;
      }
    }
  }
  .lower-roman {
    list-style-type: lower-roman;
  }
  .upper-roman {
    list-style-type: upper-roman;
  }
  .upper-alpha {
    list-style-type: upper-alpha;
  }
  .disc-list {
    list-style-type: disc;
  }
  video {
    width: 100%;
  }
}
.table-responsive {
  .table {
    width: 100%;
    border: 0.1rem solid var(--light-grey-color);
    border-collapse: collapse;
    margin-bottom: 3rem;
  }
  tr {
    border-bottom: 0.1rem solid var(--light-grey-color);
  }
  thead {
    th {
      background-color: var(--kabaddi-primary-color);
      color: var(--kabaddi-secondary-color);
      font-family: $font-bold;
    }
  }
  tbody {
    th,
    td {
      color: var(--kabaddi-secondary-color);
    }
    th {
      font-family: $font-bold;
    }
  }
  th {
    height: 4rem;
    font-size: 1.4rem;
    font-family: $font-regular;
    font-weight: normal;
  }
  th,
  td {
    border-right: 0.1rem solid var(--light-grey-color);
    text-align: left;
    padding: var(--full-space);
    &:last-child {
      border-right: none;
    }
  }
}
.waf-component.waf-error-page {
  padding: calc(var(--full-space) * 3) 0;
  min-height: calc(var(--window-inner-height) - (var(--main-header-height) + var(--footer-bottom-height)));
  @include flex-config(flex, null, center, center);
  .error-page-image {
    margin-bottom: var(--full-space);
  }
  .error-page-content {
    text-align: center;
    .title {
      margin-bottom: var(--full-space);
    }
  }
}
.waf-about-page {
  .waf-body {
    background: url(/static-assets/images/cssimages/about-mobile.png?v=#{$image-version}) no-repeat;
    background-size: 100% 100%;
    padding: 4rem var(--full-space);
  }
  .avatar-wrapper {
    @include flex-config(flex, null, null, center);
  }
  .avatar-img {
    flex-basis: 90%;
    @include flex-config(flex, null, flex-end, null);
  }
  img {
    width: auto;
    height: 17.3rem;
  }
  .text-head {
    font-family: $font-bold;
    font-size: 3rem;
    margin-left: 1rem;
  }
  .about-text {
    text-align: center;
    font-size: 1.6rem;
  }
}
.waf-kabaddi-trial.waf-component {
  @extend %p-t-full;
}
.waf-kabaddi-trial {
  .link-container {
    position: relative;
  }
  .sign-up-btn {
    border: none;
    background-color: unset;
    @extend %w-100;
    @extend %h-100;
    @extend %position-t-l;
    @extend %font-zero;
  }
  .ad-text {
    color: var(--kabaddi-secondary-color);
    font: 1.6rem/2rem $font-regular;
    @extend %p-half;
    @extend %m-y-zero;
    @extend %text-center;
    &:last-child {
      @extend %p-t-zero;
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  .waf-component.waf-error-page {
    .error-page-wrap {
      @include flex-config(flex, null, null, center);
    }
    .error-page-image {
      margin-bottom: 0;
      margin-right: var(--full-space);
    }
    .error-page-content {
      text-align: left;
    }
  }
  .table-responsive {
    .table {
      width: 100%;
      margin-top: 1rem;
    }
  }
  .waf-about-page {
    padding: 0;
    // margin-top: 3rem;
    .waf-body {
      background: url(/static-assets/images/cssimages/about-web.png?v=#{$image-version}) no-repeat;
      padding: 0 6rem;
      height: 33.7rem;
      position: relative;
      @include flex-config(flex, column, center, null);
    }
    .avatar-img {
      position: absolute;
      bottom: 0;
      left: 6rem;
    }
    img {
      height: 32.4rem;
    }
    .text-head {
      margin: 0;
    }
    .avatar-wrapper,
    .about-text {
      text-align: left;
      margin-left: 30%;
    }
  }
  .waf-kabaddi-trial.waf-component {
    padding-top: calc(var(--full-space) * 2);
    padding-bottom: calc(var(--full-space) * 1.5);
  }
  .waf-kabaddi-trial {
    .ad-text {
      padding: var(--full-space);
      font-size: 1.9rem;
      line-height: 3rem;
    }

    a {
      font-size: 1.9rem;
      color: var(--color-link);
    }

    .link-container {
      img {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
      }
    }
  }
}